<template>
  <tr>
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="justify-center">
      {{props.item.id}}
    </td>
    <td class="justify-center">
      <strong>{{props.item.name}}</strong>
      <br>
      <span v-if="props.item.responsible">
        {{props.item.responsible.name}}
      </span>
      <span v-else>
        {{props.item.company.name}}
      </span>
    </td>
    <td class="justify-center">
      <v-btn @click="openStockProducts()" outline color="primary" small>
        <v-icon>move_to_inbox</v-icon> 
        <span> Produtos</span>
      </v-btn>
      <v-btn @click="openMovements()" outline color="primary" small>
        <v-icon>swap_horiz</v-icon> 
        <span> Movimentações</span>
      </v-btn>
      <v-btn @click="openTransfers()" outline color="primary" small>
        <v-icon>import_export</v-icon> 
        <span> Transferências</span>
      </v-btn>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'Stocks',
    methods: {
      openStockProducts: function () {
        this.$WiListDialog({
          wiConfig: 'stock-product',
          filter: {where: 'stock_id,' + this.props.item.id}
        })
      },
      openMovements: function () {
        this.$WiListDialog({
          wiConfig: 'stock-movement',
          filter: {where: 'stock_id,' + this.props.item.id}
        })
      },
      openTransfers: function () {
        this.$WiListDialog({
          wiConfig: 'stock-transfer',
          filter: {
            orWhere: 'stock_in_id,' + this.props.item.id + ';stock_out_id,' + this.props.item.id
          }
        })
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>